































































































































































































































































































































































































































































import 'animate.css';
import { isMobile } from '@/mixins/isMobile';
import { functions } from '@/mixins/functions';
import ENV from '@/environment';

import { mapState } from 'vuex';

import Download from '@/components/includes/Download.vue';
import Dropdown from '@/components/includes/Dropdown.vue';
import modal from '@/components/includes/Modal.vue';
import Instructions from '@/components/Instructions.vue';
import TariffsModal from '@/components/includes/TariffsModal.vue';
import QRDownload from '@/components/includes/QRDownload.vue';

window.$ = window.jQuery = require('jquery');
window.fn = require('owl.carousel');

console.log('version 1.0');

export default {
  name: 'App',
  components: {
    QRDownload,
    Download,
    modal,
    'v-instructions': Instructions,
    TariffsModal,
    'v-dropdown': Dropdown,
  },
  mixins: [isMobile, functions],

  data() {
    const langs = [
      {
        title: 'русский',
        value: 'ru',
      },
    ];
    if (ENV.REGION === 'ru') {
      langs.push({
        title: 'english',
        value: 'en',
      });
    }
    return {
      currentPage: 'Home',
      policy: '',
      isDownloadLeft: false,
      isDownloadRight: false,
      isDownloadHidden: true,
      isFooterHidden: false,
      isHeaderHidden: false,
      isHamburgerHidden: true,
      isMobileFooterHidden: false,
      showTariffModal: false,
      showAirportInstructionModal: false,
      langs,
      subMenuLanguage: true,
      env: ENV,
      isShowQrModal: false,
    };
  },
  computed: {
    ...mapState('mapStore', {
      tariffs: (state: any) => state.tariffShort,
    }),
    region() {
      return this.env.REGION;
    },
    downloadClass() {
      return {
        'download-right': this.isDownloadRight,
        'download-left': this.isDownloadLeft,
        'download-hide': this.isDownloadHidden,
      };
    },
  },

  mounted() {
    this.policy = localStorage.getItem('policy');
    const UA = window.navigator.userAgent.toLowerCase();
    const isAndroid = UA && UA.indexOf('android') > 0;
    const isIOS = UA && /iphone|ipad|ipod|ios/.test(UA);

    // TODO remove jQuery, use plain old javascript instead
    if (isAndroid || isIOS) {
      window.$('.cursor').hide();
    }

    window.$('body').mousemove((e) => {
      window.$('.cursor').css({
        left: parseInt(e.pageX) + 'px',
        top: parseInt(e.pageY) + 'px',
      });
    });

    window.$(document).on('mouseover mouseout', 'a, .link', (event) => {
      if (event.type === 'mouseover') {
        window.$('.cursor').addClass('hover');
      } else {
        window.$('.cursor').removeClass('hover');
      }
    });

    document.body.addEventListener(
      'mouseover',
      (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if (target.tagName !== 'A') {
          window.$('.cursor').removeClass('hover');
        }
      },
      true,
    );

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  },

  methods: {
    handleLanguageSelect(lang) {
      localStorage.setItem('lang', lang);
      this.$root.$i18n.locale = lang;
    },
    toggleHeaderMenu() {
      this.$store.commit('ui/toggleHeaderMenuOpen');
      this.$store.commit('ui/hideFooterMenu');
    },
    toggleFooterMenu() {
      this.$store.commit('ui/toggleFooterMenuOpen');
      this.$store.commit('ui/hideHeaderMenu');
    },
    loaded(data) {
      this.isDownloadLeft =
        data.isDownloadLeft !== undefined ? data.isDownloadLeft : false;
      this.isDownloadRight =
        data.isDownloadRight !== undefined ? data.isDownloadRight : false;
      this.isDownloadHidden = !!(!this.isDownloadLeft && !this.isDownloadRight);

      this.currentPage = data.page ? data.page : false;
      this.$store.commit('ui/hideFooterMenu');
      this.$store.commit('ui/hideHeaderMenu');

      this.isFooterHidden =
        data.isFooterHidden !== undefined ? data.isFooterHidden : false;
      this.isHeaderHidden =
        data.isHeaderHidden !== undefined ? data.isHeaderHidden : false;
      this.isHamburgerHidden = !this.isHeaderHidden;

      this.isMobileFooterHidden =
        data.isMobileFooterHidden !== undefined
          ? data.isMobileFooterHidden
          : false;
    },
    checkHamburgerVisibility() {
      if (this.isMobile()) {
        this.isHeaderHidden = true;
        this.isFooterHidden = true;
        this.isHamburgerHidden = false;
      } else {
        this.isHamburgerHidden = !this.isHeaderHidden;
      }

      return {};
    },
    closeTariffsModal() {
      this.$store.commit('mapStore/setShowTariffsModal', { showTariff: false });
    },
    closeAirportInstructionModal() {
      this.$store.commit('mapStore/setShowAirportInstructionModal', {
        showInstructionModal: false,
      });
    },
  },
};
